var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-w-full tw-h-auto",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden left-side lg:tw-flex",attrs:{"sm":"12","lg":"4"}},[_c('OnboardingLeft')],1),_c('v-col',{staticClass:"right-side",attrs:{"sm":"12","lg":"8"}},[_c('v-col',{staticClass:"right-side-body scroll",attrs:{"sm":"12"}},[_c('img',{staticClass:"mb-3 mt-6 img-logo",staticStyle:{"height":"34px","width":"136px"},attrs:{"src":require("@/assets/logos/blue.png"),"alt":"logo"}}),_c('p',{staticClass:"welcome tw-mt-0"},[_vm._v("Personal Identity")]),_c('p',{staticClass:"admin tw-mt-2"},[_vm._v(" Your name should be the same as your account name. ")]),_c('v-row',{staticClass:"upload-div"},[_c('v-col',{attrs:{"lg":"6","sm":"6"}},[_c('p',{staticClass:"upload-div-text "},[_vm._v("Profile Image")]),_c('button',{staticClass:"button-logo-input",class:_vm.image ? 'border-0' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.onLogoFile()}}},[(_vm.logoImageLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute"},attrs:{"size":100,"width":3,"color":_vm.color,"indeterminate":""}}):_vm._e(),(_vm.recoveredImg)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.recoveredImg,"alt":""}}):(_vm.logoImage)?_c('img',{staticClass:"button-logo-img",attrs:{"src":_vm.image,"alt":""}}):_c('img',{staticClass:"button-logo-input-img",attrs:{"src":require("@/assets/camera.png"),"alt":""}})],1),_c('input',{ref:"logoImage",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.onLogoImagePicked}})])],1),_c('div',{staticClass:"pl-3 convert"},[_c('v-col',{staticClass:"mb-md-1",attrs:{"sm":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("First Name")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{attrs:{"solo":"","placeholder":"Davies","type":"text","color":"#004aad","hide-details":""},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('v-col',{staticClass:"mb-md-1",attrs:{"lg":"6","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Last Name")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Uyi","solo":"","hide-details":"","type":"text","required":""},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('div',{staticClass:"pl-3 convert"},[_c('v-col',{staticClass:"mb-md-1",attrs:{"sm":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Email")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-text-field',{attrs:{"solo":"","placeholder":"davies@gmail.com","type":"text","color":"#004aad","disabled":"","hide-details":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('v-col',{staticClass:"mb-md-1",attrs:{"lg":"6","sm":"12"}},[_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required"}},[_c('label',{staticClass:"label"},[_vm._v("Phone Number")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2"},[_c('phone-number',{attrs:{"phone-number-exist":_vm.phoneNumber,"disabled":!!_vm.phoneNumber}})],1)])],1)],1),_c('div',{staticClass:"pl-3 convert"},[_c('v-col',{staticClass:"mb-md-1",attrs:{"sm":"12","lg":"6"}},[_c('ValidationProvider',{attrs:{"name":"Date of Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"label"},[_vm._v("Date of Birth")]),_c('div',{staticClass:"tw-pt-1 mb-1 mt-2",class:classes},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"20-11-2002","append-icon":"mdi-calendar","readonly":"","solo":"","hide-details":""},model:{value:(_vm.dateOfBirth),callback:function ($$v) {_vm.dateOfBirth=$$v},expression:"dateOfBirth"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.dateOfBirth),callback:function ($$v) {_vm.dateOfBirth=$$v},expression:"dateOfBirth"}})],1),_c('span',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('div',{staticClass:"btn-div"},[_c('button',{staticClass:"btn-div-text",attrs:{"disabled":!_vm.formIsValid},on:{"click":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)}}},[_c('span',[_vm._v("Next")]),_c('img',{staticClass:"btn-div-img",attrs:{"src":require("@/assets/arrow_forward.svg"),"alt":""}})])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }